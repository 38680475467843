<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable vue/html-self-closing -->
<template>
  <div>
    <h2>สร้างรูป</h2>
    <br />
    <b-card>
      <b-overlay :show="loading" rounded="sm">
        <b-row>
          <b-col sm="12" md="6" class="mb-1">
            <div class="container-image-preview">
              <small>รูปที่เลือก</small>
              <img v-if="previewUrl" :src="previewUrl" alt="original image" />
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="container-image-preview">
              <small>รูปที่สร้าง</small>
              <b-button
                :disabled="!previewCreateUrl"
                variant="flat-warning"
                class="btn-icon"
                size="sm"
                @click="downloadImagePreviewCreate"
              >
                <feather-icon icon="DownloadIcon" />
              </b-button>
              <img v-if="previewCreateUrl" :src="previewCreateUrl" alt="create image" />
            </div>
          </b-col>
          <b-col sm="12" class="mt-1 mt-sm-0">
            <b-row>
              <b-col sm="12" md="6">
                <b-form-input v-model="form.firstName" placeholder="ชื่อ" />
              </b-col>
              <b-col sm="12" md="6" class="mt-1 mt-sm-0">
                <b-form-input v-model="form.lastName" placeholder="นามสกุล" />
              </b-col>
              <b-col v-if="data.hasZodiac" sm="12" class="mt-1">
                <v-select v-model="form.zodiac" label="name" :options="data.optionsZodiac" :clearable="false" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <div class="mt-1 text-right">
          <b-button v-if="hasData" variant="primary" @click="createImage">สร้างรูป</b-button>
          <b-button v-else variant="primary" @click="$router.push({ name: 'wallpapers' })">เลือกรูปภาพ</b-button>
        </div>
      </b-overlay>
    </b-card>
    <!-- <pre>{{ form }}</pre>
    <pre>{{ data }}</pre> -->
  </div>
</template>

<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: { vSelect, ToastificationContent },
  data() {
    return {
      data: {
        topic_image_data_id: null,
        topic_image_list_data_id: null,
        optionsZodiac: [],
        hasZodiac: false,
      },
      form: {
        firstName: '',
        lastName: '',
        zodiac: null,
      },
      previewUrl: null,
      previewCreateUrl: null,
      loading: false,
      hasData: false,
    }
  },
  mounted() {
    const { params } = this.$route
    // console.log('params', params)
    if (Object.keys(params).length > 0) {
      const { topic_image_data_id, topic_image_list_data_id, zodiac, previewUrl } = params
      // eslint-disable-next-line camelcase
      this.data.topic_image_data_id = topic_image_data_id
      // eslint-disable-next-line camelcase
      this.data.topic_image_list_data_id = topic_image_list_data_id
      this.previewUrl = previewUrl
      if (zodiac) {
        // const { topic_image_list_datas } = zodiac
        // // eslint-disable-next-line camelcase
        // this.data.optionsZodiac = [...topic_image_list_datas]
        // // eslint-disable-next-line prefer-destructuring, camelcase
        // this.form.zodiac = [...topic_image_list_datas][0]
        // this.data.hasZodiac = true
        const { id } = zodiac
        this.getOptionsZodiac(id)
      }
      this.hasData = true
    } else {
      this.data = { topic_image_data_id: null, topic_image_list_data_id: null, optionsZodiac: [] }
      this.hasData = false
    }
  },
  methods: {
    async createImage() {
      if (!this.form.firstName || !this.form.lastName) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'โปรดระบุชื่อและนามสกุล',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
        return
      }

      const requestData = {
        text: `${this.form.firstName} ${this.form.lastName}`,
        topic_image_data_id: this.data.topic_image_data_id,
        topic_image_list_data_id: this.data.topic_image_list_data_id,
        topic_image_data_zodiac_id: this.form.zodiac?.id ?? '',
        has_zodiac: this.data.hasZodiac,
        form_data: {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
        },
        app_price: 0,
        watermark: true,
      }
      //   console.log('requestData', requestData)
      this.loading = true
      const data = await this.api.post('/api/own/test/generate-image', requestData, false)
      //   console.log('data', data)
      this.previewCreateUrl = data.url
      this.loading = false
    },
    async downloadImagePreviewCreate() {
      const link = document.createElement('a')
      link.href = this.previewCreateUrl
      link.setAttribute('download', `OngphraWallpaper-${new Date().toLocaleString()}.png`)
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    async getOptionsZodiac(zodiacId) {
      this.data.hasZodiac = true
      this.loading = true
      // console.log('getOptionsZodiac', zodiacId)
      const resp = await this.api.get(`api/admin/admin-topic-images/fetch-all-zodiac/${zodiacId}`).catch(() => null)
      if (resp) {
        this.data.optionsZodiac = [...resp]
        // eslint-disable-next-line prefer-destructuring
        this.form.zodiac = [...resp][0]
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.container-image-preview {
  width: 100%;
  height: 300px;
  background-color: #47526f;
  border-radius: 0.5rem;
  position: relative;
  overflow: hidden;
  small {
    position: absolute;
    color: #fff;
    top: 10px;
    left: 10px;
  }
  button {
    position: absolute;
    top: 0;
    right: 0;
  }
  img {
    margin: 0 auto;
    display: block;
    // width: 100%;
    height: 100%;
  }
}
</style>
